// Enums
import { EnvName } from '@enums/environment.enum';

// Packages
import packageInfo from '../../package.json';

const msAuthUrl = 'https://dev.olympus.enigmacamp.com/api/auth/';
const msReferenceUrl = 'https://dev.olympus.enigmacamp.com/api/reference/';
const msTalentUrl = 'https://dev.olympus.enigmacamp.com/api/user/';
const baseUrl = 'https://dev.olympus.enigmacamp.com/api/';

const recaptcha = {
  siteKey: '6LfZimYoAAAAAH3MDn_dJJ2dbVM-7ZGApbc3d1vn',
  secretKey: '6LfZimYoAAAAADrH27Rw3QF1U_I3IS6RqmSf1K1S'
}

export const environment = {
  production: true,
  version: packageInfo.version,
  backOfficeVersion: packageInfo.backOfficeVersion,
  appName: 'Olympus',
  envName: EnvName.STAGING,
  defaultLanguage: 'en',
  msAuthUrl: msAuthUrl,
  msTalentUrl: msTalentUrl,
  msReferenceUrl: msReferenceUrl,
  baseUrl: baseUrl,
  recaptcha,
  isCaptchaEnabled: true,
  googleClientId: '868221720109-sd001981li684pae83e8gdp54k1mpifh.apps.googleusercontent.com',
  enableTalentRoutes: true,
  enableBackOfficeRoutes: false,
  isBackOfficePage: false,
};
